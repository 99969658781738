@font-face {
    font-family: "Satoshi-Regular";
    src: url("../assets/fonts/static/Satoshi-Regular.otf");
}

@font-face {
    font-family: "Satoshi-Light";
    src: url("../assets/fonts/static/Satoshi-Light.otf");
}

@font-face {
    font-family: "Satoshi-Medium";
    src: url("../assets/fonts/static/Satoshi-Medium.otf");
}

@font-face {
    font-family: "Satoshi-Bold";
    src: url("../assets/fonts/static/Satoshi-Bold.otf");
}

body {
    font-family: "Satoshi-Light" !important;
    scroll-snap-type: y mandatory;
}

p {
    margin-bottom: 0px !important;
    /* letter-spacing: 1px; */
}

/* Common ClassName Css */

.paraWhite_margin_top {
    margin-top: 20px;
    color: white;
}

.button_red {
    background: linear-gradient(103.16deg, #BA2031 48.78%, #6C1213 77.8%);
    color: white;
    font-family: "Satoshi-Regular" !important;
    padding: 8px 25px 8px 25px;
    border-radius: 30px;
    border: 1px solid #BA2031;
}

.button_white {
    background: white;
    color: rgb(0, 0, 0);
    font-family: "Satoshi-Regular" !important;
    padding: 8px 25px;
    border-radius: 30px;
    border: 1px solid #ffffff;
}

/* for icons */
.our_value_icon {
    width: 60px;
    height: auto;
    object-fit: contain;
    border-radius: 13px;
}

.headings_text_white {
    color: rgb(255, 255, 255);
    font-family: "Satoshi-Regular" !important;
}

.heading_para {
    font-family: "Satoshi-Regular" !important;
    color: #000000;
}

/*  */


/* model css */
/* FullPageModal.css */
.full-page-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.5);
}

.modal-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
}

.modal-content {
    position: relative;
    background: white;
    width: 90%;
    max-width: 800px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    border: none;
    background: none;
    cursor: pointer;
}

/*  */

/* chatbot */
.chatbox-container {
    position: fixed;
    bottom: 160px;
    right: 0px;
    display: flex;
    align-items: center;
    gap: 10px;
    background: transparent;
    cursor: pointer;
    transition: all 0.3s ease;
    z-index: 1000;
    background-color: rgb(0, 0, 0);
    padding: 15px 16px 15px 7px;
    border-top-left-radius: 35px;
    border-bottom-left-radius: 35px;
}

.chatbox-icon {
    background: black;
    color: rgb(255, 255, 255);
    font-size: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease;
    margin-left: 15px;
}

.chatbox-text {
    color: rgb(255, 255, 255);
    /* padding: 8px 16px; */
    border-radius: 16px;
    font-size: 15px;
    margin-left: 10px;
    opacity: 0;
    transform: translateX(20px);
    white-space: nowrap;
    transition: opacity 0.3s ease, transform 0.3s ease;
}

.chatbox-container.hovered .chatbox-icon {
    transform: scale(1);
}

.chatbox-container.hovered .chatbox-text {
    opacity: 1;
    transform: translateX(0);
}

/******************************************** NAVBAR CSS START **********************************************/
@media all and (min-width: 992px) {
    .dropdown-menu {
        width: 13rem;
    }

    .mega-submenu {
        left: 100%;
        top: 0;
        min-width: 25rem;
    }

    .ktm-mega-menu {
        position: static;
    }

    .mega-menu {
        left: 0;
        right: 0;
        width: 100%;
    }

    .dropdown-menu li {
        position: relative;
    }

    .dropdown-menu .submenu {
        display: none;
        left: 100%;
        top: 0;
    }

    .dropdown-menu>li:hover>.submenu,
    .dropdown:hover>.dropdown-menu {
        display: block;
    }
}

.home-navbar .navbar-light .navbar-nav .nav-link.active,
.home-navbar .navbar-light .navbar-nav .nav-link:hover {
    color: #a01d26 !important;

}

.navbar-light .navbar-nav .nav-link {
    color: rgb(0 0 0);
    font-family: "Satoshi-Medium" !important;
}

.navbar-light .navbar-nav .nav-link-scrolled {
    color: rgb(255, 255, 255);
    font-family: "Satoshi-Medium" !important;
}

.bg-light-nav-top {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
    height: 90px;
}

.bg-light-nav-top-scrolled {
    background-color: rgb(0, 0, 0);
    transition: 1s;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
    height: 90px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

@media (min-width: 992px) {
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 1.5rem;
        padding-left: 1.5rem;
    }
}

.logo-img {
    width: 200px;
    height: auto;
    object-fit: contain;
}

.home-navbar {
    padding: 15px;
}

.contact-btn {
    color: #fff;
    background-color: #a01d26;
    border-color: #a01d26;
    border-radius: 25px;
    padding: 9px 22px;
    font-family: "Satoshi-Medium" !important;
}

.social_media i {
    cursor: pointer;
    padding-right: 30px;
    font-size: 25px;
}

.social_media i:hover {
    cursor: pointer;
    padding-right: 13px;
    font-size: 25px;
    color: #a01d26;
}

.nav-item .contact-btn {
    color: #fff;
    background-color: #a01d26;
    border-color: #a01d26;
    border-radius: 25px;
    padding: 9px 22px;

}

.social_media_img {
    width: 100%;
    height: auto;
    object-fit: contain;
}

.navleft_link h6 {
    color: #fff;
    font-family: "Satoshi-Medium";
}

.navleft_link p {
    color: #fff;
    font-size: 10px !important;
    letter-spacing: 1px;
}

.mails_tags {
    text-decoration: none !important;
    color: white;
}

.navbar_footer {
    padding: 3px 25px 5px;
    border-radius: 8px;
    margin-top: 15px;
    background: linear-gradient(102.01deg, #BA2031 4.15%, #6C1213 93.75%);
    border: 2.37px solid;
    border-image-source: linear-gradient(92.64deg, #FFFFFF -13.07%, rgba(255, 255, 255, 0) 6.86%, rgba(255, 255, 255, 0) 88.43%, rgba(255, 255, 255, 0.42) 104.39%);
}

.navbar_footer a {
    font-size: 13px;
}

.contact_arrow {
    transform: rotate(318deg) !important;
    border-radius: 50%;
    border: 1px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    height: 20px;
    width: 20px;
    color: white;
}

.circle_arrow {
    /* transform: rotate(308deg) !important; */
    border-radius: 50%;
    border: 1px solid #797979;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    height: 25px;
    width: 25px;
    padding: 15px;
    color: #797979;
    cursor: pointer;
}


.contact-btn:focus+.contact-btn,
.contact-btn:focus,
.contact-btn:hover,
.contact-btn.active,
.contact-btn:active {
    color: #a01d26;
    background-color: #ffffff;
    border-color: #a01d26;
    box-shadow: 0 0 0 0.25rem rgb(211 212 213 / 0%);
}

.active .contact-btn {
    color: #a01d26;
    background-color: #ffffff;
    border-color: #a01d26;
    box-shadow: 0 0 0 0.25rem rgb(211 212 213 / 0%);
}

.btn-check:active+.btn-primary:focus,
.btn-check:checked+.btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.25rem rgb(49 132 253 / 1%);
}

.navbar-nav .dropdown-menu {
    background: #000000ad;
    backdrop-filter: blur(50px);
    z-index: 100000;
}

.tabs .tab {
    padding: 15px 25px;
    align-items: center;
    color: white;
    cursor: pointer;
    font-size: 18px;
    margin-bottom: 20px;
}

.tabs .tab.active {
    border-bottom: 1px solid #a01d26;
    color: #ffffff;
    background: linear-gradient(90deg, #BA2031 0%, #540E16 100%);
    padding: 15px 25px;
    border-radius: 5px;
}

.tabs .tab:hover {
    border-bottom: 1px solid #a01d26;
    color: #ffffff;
    background-color: #a01d26;
    font-weight: 500;
}

.mega-menu-inner {
    padding: 20px;
}

.padd-left {
    padding-left: 30px;
    height: 400px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.cycle-style {
    width: 25px;
    margin-right: 10px;
    height: fit-content;
}

/* .padd-left p {
    font-size: 14px;
    line-height: 0px;
    color: #fff;
} */

.bg-food-agr {
    padding: 5px;
    cursor: pointer;
}

.bg-food-agr.active {
    background-color: #3ec5fa;
}

.inner-icon {
    width: 30px;
    height: 30px;
}

.bg-star-icon {
    padding: 10px;
    background-color: #fff;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
        rgba(17, 17, 26, 0.1) 0px 0px 8px;
    border-radius: 8px;
    margin: 3px;
    margin-right: 8px;
}

.card {
    box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
    border-radius: 10px;
    margin-bottom: 20px;
}

/******************************************** ContactUs Starts **********************************************/
.contact_parent_div {
    margin-top: 90px;
    /* background-image: url("../assets/images/lines_background.png"); */
}

.contact_banner_img {
    width: 307px;
    height: auto;
    object-fit: contain;
}

.contact_banner_img2 {
    width: 204px;
    height: auto;
    object-fit: contain;
}

.lets_connect_img {
    width: 210px;
    height: auto;
    object-fit: contain;
}

.lets_connect_img2 {
    width: 140px;
    height: auto;
    object-fit: contain;
}

.contact_banner_parent {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 140px 0px;
    position: relative;
}

.contact_banner_parent p {
    font-size: 20px;
}

.let_connect_left {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    flex-direction: column;
    margin: 60px 0px;
}

.contact_banner_head {
    font-size: 70px;
    font-family: "Satoshi-Regular" !important;
    text-align: center;
}

.let_connect_head {
    font-size: 50px;
    font-family: "Satoshi-Medium" !important;
    color: white;
}

.contact_bar_whiteback {
    position: absolute;
    top: -100px;
    left: 365px;
    display: none;
}

.contact_bar_bottom_whiteback {
    right: 290px;
    position: absolute;
    bottom: -80px;
    z-index: -1;
    display: none;
}

.get_in_touch {
    background-color: black;
    padding: 70px 0px;
}

.get_in_touch_parent {
    background-color: white;
}

.form_heading {
    font-family: "Satoshi-Regular";
    color: white;
}

.form_parent_div {
    background: linear-gradient(88.3deg, rgba(255, 255, 255, 0.1245) 0%, rgba(255, 255, 255, 0.066) 99.66%);
    border: 2.65px solid;
    border-image-source: linear-gradient(92.64deg, rgba(255, 255, 255, 0.5) -13.07%, rgba(255, 255, 255, 0) 6.86%, rgba(255, 255, 255, 0) 88.43%, rgba(255, 255, 255, 0.21) 104.39%);
    padding: 40px
}

.form_input {
    background: #FFFFFF1A;
    border: none !important;
    background: #FFFFFF1A;
    height: 40px;
    padding-left: 18px;
    color: white;
    margin-top: 13px;
}

.form_input::placeholder {
    color: #9C9C9C;
    font-size: 13px;
}

.form_input:focus-visible {
    outline: none;
}

.submit_btn {
    background: linear-gradient(95.65deg, #BA2031 -0.49%, #6C1213 99.7%);
    padding: 8px 25px;
    border: none;
    color: white;
    border-radius: 5px;
}

.map_images {
    width: 90px;
    height: auto;
    object-fit: contain;
}

.address_div p {
    color: white;
}

/******************************************** Footer Starts **************************************/
.footer_boxes h5 {
    font-family: "Satoshi-Medium" !important;
    color: black;
    margin-top: 75px;
    margin-bottom: 50px;
}

.logo_section p {
    padding-top: 5px;
}

.let_connect_section {
    background-image: url("../assets/images/verticalLine_redbackground.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
}

.logo_section_footer {
    margin-top: 80px;
}

.footer_links {
    text-decoration: none;
    color: #3F2F2F;
    font-family: "Satoshi-Regular" !important;
    margin-bottom: 12px;
    cursor: pointer;
}

.footer_links:hover {
    color: #a01d26;
}

.footer_bottom {
    background: linear-gradient(90deg, #BA2132 0%, #6D1314 100%);
    border: none;
    padding: 15px 0px;
}

.copy_right p {
    color: white;
    margin-right: 50px;
}

.footer_bottom .footer_links {
    color: white;
    padding: 0px 15px;
}

.footer_bottom .footer_links:hover {
    text-decoration: underline;
}

/******************************************** AboutUs Css Start **********************************************/

.aboutus_main .bg-light-nav-top {
    background-color: black;
}

.aboutus_main .navbar-light .navbar-nav .nav-link {
    color: white;
}

.aboutus_main .contact-btn {
    color: #fff;
    background-color: transparent;
    border-color: white;
    border-radius: 25px;
    padding: 9px 22px;
    font-family: "Satoshi-Medium" !important;
}

.aboutus_parent_section {
    margin-top: 90px;
    /* background-image: url("../assets/images/vertical_lines_back_black.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-color: black; */
}

.aboutus_banner_section {
    margin-top: 90px;
    background-image: url("../assets/images/vertical_lines_back_black.png");
    background-repeat: no-repeat;
    /* background-size: cover; */
    background-attachment: fixed;
    background-position: center;
    background-color: black !important;
}

.purpose_left h1 {
    color: black;
    /* font-family: "Satoshi-Regular" !important; */
    font-size: 50px;
}

.purpose_right p {
    padding-bottom: 15px;
}

.purpose_image {
    width: 100%;
    height: auto;
    object-fit: contain;
}

.purpose_img_top_content {
    font-family: "Satoshi-Medium" !important;
    font-size: 34px;
    position: absolute;
    top: 35%;
    left: 26%;
    color: white;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

.section_padding {
    padding: 80px 0px;
}

.section_padding_top {
    padding: 80px 0px 0px;
}

.section_padding_bottom {
    padding: 0px 0px 80px;
}

.section_margin {
    margin: 80px 0px;
}

.purposeof_se_parent_section {
    padding-top: 80px;
}

.purpose_img_bottom_content {
    font-family: "Satoshi-Medium" !important;
    font-size: 30px;
    position: absolute;
    bottom: 9%;
    left: 13%;
}

.bar_about_top {
    position: absolute;
    top: -22%;
    z-index: 10000;
    right: 25%;
    display: none;
}

.bar_about_bottom {
    position: absolute;
    bottom: 0%;
    /* z-index: 10000; */
    left: 13%;
    display: none;
}

/* circle css */

.circle-center {
    height: 74px;
    width: 74px;
    border-radius: 50%;
    text-align: center;
    padding: 22px 17px;
    position: absolute;
    animation: borderPulseVideo 1000ms infinite ease-out;
}

.circle-center-link {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

#circle svg {
    width: 250px;
    height: 240px;
    -webkit-animation-name: rotate;
    -moz-animation-name: rotate;
    -ms-animation-name: rotate;
    -o-animation-name: rotate;
    animation-name: rotate;
    -webkit-animation-duration: 14s;
    -moz-animation-duration: 14s;
    -ms-animation-duration: 14s;
    -o-animation-duration: 14s;
    animation-duration: 14s;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    -ms-animation-iteration-count: infinite;
    -o-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-timing-function: linear;
    -ms-animation-timing-function: linear;
    -o-animation-timing-function: linear;
    animation-timing-function: linear;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

#circle .text-rotatet {
    font-size: 18px;
    color: #fff;
    white-space: nowrap !important;
}

.advisory_main_component #circle .text-rotatet {
    font-size: 18px;
    color: #000000 !important;
    white-space: nowrap !important;
}

.down-arrow-rotate {
    width: 35px;
    height: auto;
}

.banner_circle_loop {
    position: absolute;
    top: 26%;
    right: 0%;
}

.aboutus_main .fa-computer-mouse {
    color: white !important;
}

/****************************************************** AboutUs Css Start ****************************************/
.eagle_img_parent_section {
    background-color: black;

}

.eagle_image {
    width: 100%;
    height: auto;
    object-fit: contain;
}

.eagle_section_right p {
    color: white;
    padding-bottom: 15px;
}

.about-btn {
    color: #fff;
    background-color: #a01d26;
    border-color: #a01d26;
    border-radius: 25px;
    padding: 6px 22px;
    font-family: "Satoshi-Medium" !important;
    margin-top: 10px;
}

.our_philosophy_ubuntu {
    background: linear-gradient(91.27deg, #BA2132 43.51%, #6D1314 69.53%);
    padding: 20px 0px;
}

.our_philosophy {
    background: linear-gradient(91.27deg, #BA2132 43.51%, #6D1314 69.53%);
    padding: 50px 0px;
}

.our_philosophy_ubuntu p {
    color: white;
    font-size: 30px;
    text-align: center;
}

/****************************************************** vertical carosel css start ****************************************/

.vertical_marque {
    height: 10em;
    overflow: hidden;
    background: #ffffff;
    position: relative;
}

.slider {
    position: relative;
    box-sizing: border-box;
    animation: slider 55s linear infinite;
    list-style-type: none;
    margin: 0;
    padding: 0 1em;
    line-height: 1.5em;
    text-align: center;
}

.slider2 {
    position: relative;
    box-sizing: border-box;
    animation: slider 25s linear infinite;
    list-style-type: none;
    margin: 0;
    padding: 0 1em;
    line-height: 1.5em;
    text-align: center;
}

@keyframes slider {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-100%);
    }
}

.blur {
    position: relative;
}

.blur:before,
.blur:after {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 2em;
    background: linear-gradient(180deg, #ffffff, rgba(255, 255, 255, 0));
    z-index: 1;
    pointer-events: none;
}

.blur:before {
    top: 0;
}

.blur:after {
    bottom: 0;
    transform: rotate(180deg);
}

.vertical_marque .slider p {
    font-size: 35px;
    padding-bottom: 25px;
    font-family: "Satoshi-Medium" !important;
    color: #333333;
}

.vertical_marque .slider2 p {
    font-size: 35px;
    padding-bottom: 25px;
    font-family: "Satoshi-Medium" !important;
    color: #333333;
}

.vertical_corosel_parent_section h4 {
    color: #EBEBEB;
    font-size: 50px;
    font-family: "Satoshi-Medium" !important;
    font-weight: 700;
    line-height: 91.68px;
    letter-spacing: 1px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

.vertical_corosel_parent_section {
    padding: 90px 0px 100px 0px;
}

/****************************************************** Meet Our Team css start ****************************************/

.meetour_team_parent_section {
    background-image: url("../assets//images//meet_our_experts_background.png");
    /* background-position: fixed; */
    background-repeat: no-repeat;
    background-size: cover;
}

.meetour_team_tab {
    background: linear-gradient(88.3deg, rgba(255, 255, 255, 0.1245) 0%, rgba(255, 255, 255, 0.066) 99.66%);
    border-radius: 70px;
}

.meetour_team_tab p {
    color: #9C9C9C;
    text-align: center;
    padding: 13px 15px 10px 15px;
    cursor: pointer;
}

.tab_active {
    background: linear-gradient(180deg, #BA2031 0%, #6C1213 100%);
    color: #FFFFFF !important;
    border-radius: 70px;
    text-align: center;
    cursor: pointer;
}

.meet_our_team_head {
    color: #292627;
    font-size: 55px;
    font-family: "Satoshi-Medium" !important;
    font-family: Satoshi;
    font-weight: 700;
    line-height: 129.6px;
    letter-spacing: 1px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

}

.meetour_team_parent_section h1 {
    font-family: "Satoshi-Medium" !important;
}

.expert_name {
    font-size: 20px;
    font-family: "Satoshi-Bold" !important;
}

.expert_role {
    color: white;
    font-size: 10px;
    margin-top: 5px;
}

.arrow_expert {
    width: 35px;
    height: auto;
    position: absolute;
}

.experts_deta_div_bottom {
    position: absolute;
}

/* 
.expert_card {
    background-image: url("../assets//images//expert_frame.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
} */
.expert_card img {
    width: 220px;
}

.expert_card_image {}

/****************************************************** our Values css start ****************************************/
.values_section_parent_div {
    background-image: url("../assets//images//meet_our_experts_background.png");
    /* background-position: fixed; */
    background-repeat: no-repeat;
    background-size: cover;
}


.our_value_content h6 {
    color: white;
    font-family: "Satoshi-Medium" !important;
    margin-top: 20px;

}

.value_margintop {
    margin-top: 80px;
}

.carosel_image_hrsmall {
    width: 100%;
    height: auto;
}

.carosel_image_hrbig {
    width: 100%;
    height: auto;
}

/****************************************************** Company Vibes Css Start ****************************************/
.company_vibes_left h2 {
    color: black;
    /* font-family: "Satoshi-Re" !important; */
    font-size: 40px;
}

.company_vibes_left p {
    padding-bottom: 20PX;
}

.we_connect_para {
    font-family: "Satoshi-Medium" !important;
    font-size: 36px;
    color: white;
    position: absolute;
    top: 23%;
    left: 17%;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

}

/*  */
.vertical-carousel-container {
    display: flex;
    justify-content: center;
    position: relative;
    overflow: hidden;
    height: 600px;
    width: 100%;
}

.blur-top {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.8), transparent);
    z-index: 2;
    pointer-events: none;

}


.blur-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background: linear-gradient(to top, rgba(255, 255, 255, 6), transparent);
    z-index: 2;
    pointer-events: none;

}

.carousel-column {
    display: flex;
    flex-direction: column;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

.top-to-bottom {
    animation: scroll-down 10s linear infinite;

}

.bottom-to-top {
    animation: scroll-up 10s linear infinite;

}


@keyframes scroll-down {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes scroll-up {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-100%);
    }
}

.carousel-column img {
    height: auto;
    width: 150px;
    margin: 10px;
    object-fit: cover;
}

.we_connect_backimg {
    height: auto;
    width: 100%;
}

/****************************************************** technology partner css starts ****************************************/
.techno_tab_link {
    cursor: pointer;
    padding: 15px 20px;
    border-radius: 50px;
    font-family: "Satoshi-Regular" !important;
    color: #333E49;
    font-size: 15px;
}

.technology_tabs {
    background: #EEEEEE;
    padding: 5px 0px;
    border-radius: 50px;
    position: sticky;
    top: 0;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 1000;
    transition: all 0.3s ease-in-out;
    margin: 80px 0px;
    display: none !important;
}

.technology_tabs.sticky {
    position: fixed;
    /* top: 0px; Adjust for your navbar's height */
    /* width: 100%; */
    margin-top: 30px;
}


.techno_tab_active {
    background: linear-gradient(180deg, #BA2031 0%, #6C1213 100%);
    color: white;
    padding: 15px 20px;
    border-radius: 50px;
    font-family: "Satoshi-Regular" !important;
    font-size: 15px;
}

.first_technology_tab {
    /* margin-top: 265px; */
}

.first_advisory_tab {
    /* margin-top: 144px; */
}

/* .technology_mainContent_div.sticky {
    margin-top: 250px !important;
} */

.techno_image {
    width: 100%;
    height: auto;

}

/* 
.purpose_right {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
} */

.heading_serialnumber {
    color: white;
    font-size: 20px;
    background: linear-gradient(180deg, #BA2031 0%, #6C1213 100%);
    padding: 6px 17px;
    text-align: center;
    border-radius: 13px;
}

.tech_parent_para {
    text-align: justify;
    /* padding-right: 110px; */
}

.technology_parent_right {
    margin: 0px 110px;
}

.key_offerings_right h1 {
    font-family: "Satoshi-Medium" !important;
    color: white;
    font-size: 60px;
}

.key_offer_number {
    font-size: 22px;
    font-family: "Satoshi-Medium" !important;
    color: white;
    margin-bottom: 10px !important;
}

.key_offer_right_head {
    font-size: 18px;
    font-family: "Satoshi-Medium" !important;
    text-wrap: wrap;
    color: white;
}

.technology_keyOfferings {
    background-image: url("../assets/images/key_offerings_background.png");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 60px 0px 90px;
}

.key_offer_para {
    text-align: justify;
}

/****************************************************** advisory css starts ****************************************/
.Advisory_tab {
    background-color: white;
    padding: 5px 0px;
    border-radius: 50px;
}

.Advisory_bottom_image_left {
    position: absolute;
    width: 550px;
    left: 0%;
    bottom: 0%;
}

.Advisory_bottom_image_right {
    position: absolute;
    width: 550px;
    right: 0%;
    bottom: 0%;

}

.advisory_content_left h2 {
    font-family: "Satoshi-Regular" !important;
    color: white;
}

.Advisory_tab_parent {
    background-image: url("../assets/images/Advisory_background.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.advisory_content_parent {
    background-image: url("../assets/images/meet_our_experts_background.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.advisory_content_right p {
    margin-top: 1rem;
}

.why_choose_us {
    background-image: url("../assets/images/why_choose_back.png");
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
}

.advisory_details_content_left p {
    margin-top: 15px;
    color: white;
}

.why_choose_us_content p {
    text-align: center;
}

.why_choose_us_content {
    padding: 120px 0px;
}

@keyframes marqueeAnimation {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-100%);
    }
}

/* .advisory_details_intropage {
    background-image: url("../assets/images/meet_our_experts_background.png");
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
} */

.marquee {
    /* width: 100%; */
    overflow: hidden;
    white-space: nowrap;
    box-sizing: border-box;
    position: absolute;
    top: 20px;
}

.agency_detail_heading {
    background-image: url("../assets/images/Advisory_background.png");
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
}

#div1 p {
    display: inline-block;
    animation: marquee 5s linear infinite;
    font-size: 60px;
    line-height: 92.05px;
    letter-spacing: -0.022em;
    /* color: white; */
    padding: 0px 20px;
    font-family: "Satoshi-Bold" !important;
    color: black;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 2px #FFFFFF30;
}

#div2 p {
    display: inline-block;
    animation: marquee2 5s linear infinite;
    animation-delay: 5s;
    font-size: 60px;
    line-height: 92.05px;
    padding: 0px 20px;
    letter-spacing: -0.022em;
    /* color: white; */
    font-family: "Satoshi-Bold" !important;
    color: black;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 2px #FFFFFF30;
}

/* .advisory_content_right {
    padding: 45px 105px 80px 20px;
} */

/* .advisory_content_left {
    padding: 0px 120px 0px 0px;
}
.advisory_content_left2{
    padding: 0px 0px 0px 120px;
} */
@keyframes marquee {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(-100%);
    }
}

@keyframes marquee2 {
    from {
        transform: translateX(0%);
    }

    to {
        transform: translateX(-200%);
    }
}



.our_value_child_div img {
    background: linear-gradient(180deg, #BA2031 0%, #6C1213 100%);

}

.why_social_eagle h2 {
    font-size: 50px;
    line-height: 92.05px;
    padding: 0px 20px;
    letter-spacing: -0.022em;
    font-family: "Satoshi-Bold" !important;
    color: black;
    text-align: center;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 2px #FFFFFF30;
}

.why_social_eagle {
    background-image: url("../assets/images/meet_our_experts_background.png");
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
}

.why_social_eagle_center h3 {
    text-align: center;
    color: white;
    font-family: "Satoshi-Medium" !important;
}

.button_red_center {
    background: linear-gradient(103.16deg, #BA2031 48.78%, #6C1213 77.8%);
    color: white;
    font-family: "Satoshi-Regular" !important;
    padding: 8px 30px;
    border-radius: 30px;
    border: 1px solid #BA2031;
}

.why_social_eagle_center {
    background: linear-gradient(88.3deg, rgba(255, 255, 255, 0.1245) 0%, rgba(255, 255, 255, 0.066) 99.66%);
    border: 2.65px solid;
    padding: 40px 50px 40px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    display: flex;
    border-bottom: none;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    backdrop-filter: blur(50px);
    border-image-source: linear-gradient(92.64deg, rgba(255, 255, 255, 0.5) -13.07%, rgba(255, 255, 255, 0) 6.86%, rgba(255, 255, 255, 0) 88.43%, rgba(255, 255, 255, 0.21) 104.39%);
}

.why_social_eagle_center::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 150px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
    filter: blur(10px);
    pointer-events: none;
}

/* .whyseleftImg1 {
    position: absolute;
}

.whyseleftImg2 {
    position: absolute;
    top: 120%;
    left: -20%;
}

.whyseleftImg3 {
    position: absolute;
    top: 160%;
    right: 17%;
}

.whyseleftImg4 {
    position: absolute;
    top: 120%;
    left: -20%;
} */
/****************************************************** agency css starts ****************************************/

.agency_tabsection {
    background-image: url("../assets/images/vertical_lines_backWhite.png");
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
}

.why_digital_market_section {
    background-image: url("../assets/images/verticalLine_redbackground.png");
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
}

.number_list {
    color: #6C1213;
    background-color: white;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-family: "Satoshi-Medium" !important;
}

.number_list_backred {
    color: #ffffff;
    background: linear-gradient(180deg, #BA2031 0%, #6C1213 100%);
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 15px;
    font-family: "Satoshi-Medium" !important;
}

.why_digital_market_para {
    color: white
}

.why_digital_market_list {
    margin-top: 30px;
}

.why_digital_market_image {
    width: 370px;
    height: auto;
    object-fit: contain;
}

.agency_detail_listing_left {
    background-image: url("../assets/images/agency_listing_background.png");
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    padding: 40px 90px 40px 110px !important;
    height: 100vh;
    overflow-y: scroll;
}

.branding_design_listing_left {
    background-image: url("../assets/images/agency_listing_background.png");
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    padding: 40px 90px 40px 110px !important;
    height: 100%;
    /* overflow-y: scroll; */
}

.page_active {
    color: white;
}

.agency_detail_listing_right {
    padding: 50px 110px 50px 40px !important;
}

.agency_detail_listing {
    height: 100vh;
    overflow-y: auto;
}

.agency_detail_listing_left,
.agency_detail_listing_right {
    height: 100%;
    overflow-y: auto;
    position: relative;
    scroll-snap-align: start;
    scroll-behavior: smooth
}

.agency_detail_listing::-webkit-scrollbar {
    display: none;
}

.details_pages_para {
    color: #797979;
    font-size: 20px;
    padding: 30px 0px;
    border-bottom: 1px solid #79797933;
    width: 100%;
    cursor: pointer;

}

.details_pages_para_last {
    color: #797979;
    font-size: 20px;
    padding: 30px 0px;
    width: 100%;
    cursor: pointer;
}

.details_pages_para:hover {
    color: white;
    font-size: 20px;
    padding: 30px 0px;
    border-bottom: 1px solid #79797933;
    width: 100%;
    cursor: pointer;
}

.details_pages_para:hover .circle_arrow {
    transform: rotate(325deg) !important;
    border-radius: 50%;
    border: 1px solid #BA2031;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    height: 25px;
    width: 25px;
    padding: 15px;
    color: white;
    cursor: pointer;
    background: linear-gradient(131.19deg, #BA2031 44.87%, #6C1213 82.53%);
}

.circle_arrow_active {
    transform: rotate(325deg) !important;
    border-radius: 50%;
    border: 1px solid #BA2031;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    height: 25px;
    width: 25px;
    padding: 15px;
    color: white;
    cursor: pointer;
    background: linear-gradient(131.19deg, #BA2031 44.87%, #6C1213 82.53%);
}

.details_pages_para_last:hover .circle_arrow {
    transform: rotate(325deg) !important;
    border-radius: 50%;
    border: 1px solid #BA2031;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    height: 25px;
    width: 25px;
    padding: 15px;
    color: white;
    cursor: pointer;
    background: linear-gradient(131.19deg, #BA2031 44.87%, #6C1213 82.53%);
}

.paraWhite_margin_top {
    margin-top: 20px;
    color: white;
}

.details_page_whyChoose {
    width: 100%;
    height: auto;
    object-fit: contain;
    padding-right: 50px;

}

.agency_detail_whyChoose_rght {
    background-image: url("../assets/images/agency_listing_background.png");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 65px 65px 65px 90px;
    height: 100%;
    /* justify-content: center;
    display: flex;
    flex-direction: column; */
}

.agency_detail_whyChoose_left {
    padding: 65px;
    height: 100%;
}

/* .agency_detail_whyChoose_left button{
    margin-left: 20px;
} */
.agency_detail_whyChoose_rght p {
    color: white;
    margin-top: 10px;
    padding-right: 60px;
}

.agency_detail_whyChoose_rght h5 {
    margin-top: 25px;
}

.agency_detail_whyChoose_rght_content {
    margin-top: 40px;
}

/* .agency_detail_whyChoose_rght_content p {} */
/* .Agency_added_section_heading {
    background-image: url("../assets/images/vertical_lines_backWhite.png");
    background-repeat: no-repeat;
    background-size: cover;
} */
.Agency_added_content h4 {
    font-family: "Satoshi-Medium" !important;
    margin-left: 15px;
}

.Agency_added_flexdiv p {
    margin-top: 10px;
    margin-left: 15px;
}

.Agency_added_content {
    border-bottom: 2px dashed #595959;
    padding-bottom: 40px;
    margin-right: 50px;
    text-align: justify;
    margin-top: 60px;
}

.Agency_added_content_lastsection {
    border-bottom: none !important;
    padding-bottom: 40px;
    margin-right: 50px;
    text-align: justify;
    margin-top: 60px;
}

.fa-computer-mouse {
    color: rgb(0, 0, 0);
}

/* button cssss sssssssssssssssss */
.button_red span {
    cursor: pointer;
    position: relative;
    transition: 0.5s;
    width: 100%;
    display: block;
}

.button_red span:after {
    content: '\2192';
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    transition: 0.5s;
    font-weight: 700;
    transform: rotate(318deg) !important;
    border-radius: 50%;
    border: 1px solid white;
    /* display: flex;
    justify-content: center;
    align-items: center; */
    font-size: 13px;
    height: 22px;
    width: 22px;
    color: white;
}

.button_red:hover span {
    padding-right: 35px;
    color: #ffffff;
}

.button_red:hover span:after {
    opacity: 1;
    left: calc(100% - 23px);
}

.button_red_arrow {
    margin-top: 2px;
}

.button_red:hover .button_red_arrow {
    display: none;
}

.button_red:hover {
    background: black;
    border: 1px solid #BA2031;
}

/*  */
.expert_card_parent {
    background: linear-gradient(99.3deg, #BA2031 11.47%, #540E16 53.21%);
    width: 0;
    overflow: hidden;
    transition: width 0.5s ease, opacity 0.5s ease;
    position: relative;
}

/* .expert_card_parent.show {
    width: 330px;
    height: 200px;
} */

.expert_card_parent.show {
    width: 330px;
    height: 200px;
    /* Adjust as needed */
    overflow: hidden;
    opacity: 1;
}

.expert_card_parent.hide {
    width: 0;
    /* display: none; */
    opacity: 0;
}

.expert_card_child {
    background-color: black;
    z-index: 1000;
    width: 330px;
    height: 170px;
    border-bottom-left-radius: 20px;
    padding: 20px 20px 20px 25px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

}

.expert_card_child h6 {
    color: white;
}

.expert_card_child p {
    color: white;
}

.expert_img_team {
    position: absolute;
    top: 0;
    right: 0;
    width: 200px;
    height: 201px;
}

.expert_img_only img {
    width: 200px;
    height: 201px;
    cursor: pointer;
    transition: opacity 0.3s ease;
}

.expert_img_only img:hover {
    opacity: 0.1;
    /* Dim effect while hovering */
}
/****************************************  BLOGS   ************************************/

.contact_bar{
    position: absolute;
    top:-55%;
    left:17%
}
.contact_bar2{
    position: absolute;
    bottom: -44%;
    right: 22%;
}