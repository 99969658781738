@media screen and (min-width: 1440px) {
    .purpose_img_top_content {
        left: 28%;
    }

    .we_connect_para {
        left: 19%;
    }

    .bar_about_top {
        right: 30%;
    }

    .bar_about_bottom {
        left: 20%;
    }
}

@media screen and (max-width: 1280px) {
    .purpose_img_top_content {
        font-size: 32px;
        position: absolute;
        top: 32%;
        left: 26%;
    }

    .purpose_img_bottom_content {
        font-size: 25px;
    }

    .we_connect_para {
        color: white;
        font-size: 32px;
    }

    .technology_parent_right {
        margin: 40px 45px;
    }

    .heading_serialnumber {

        height: 45px;
    }

    .bar_about_top {
        right: 23%;
    }



}

.max_width_767_startsmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm {}

@media screen and (max-width: 1024px) {

    .purpose_img_top_content {
        font-size: 26px;
    }

    .purpose_img_bottom_content {
        font-family: "Satoshi-Medium" !important;
        font-size: 21px;
        position: absolute;
        bottom: 9%;
        left: 15%;
    }

    .we_connect_para {
        font-size: 26px;
    }

    .branding_design_listing_left {
        height: 100%;
    }

    .Advisory_bottom_image_right {
        position: absolute;
        width: 460px;
        right: 0%;
        bottom: 0%;
    }

    .Advisory_bottom_image_left {
        position: absolute;
        width: 480px;
        left: 0%;
        bottom: 0%;
    }

    .break_tag {
        display: none;
    }

    .bar_about_top {
        right: 22%;
    }

    .vertical-carousel-container {
        height: 660px
    }
}

.max_width_767_startsmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm {}

@media screen and (max-width: 992px) {
    .contact_bar {
        display: none !important;
    }

    .contact_bar2 {
        display: none !important;
    }

    .navbar-nav .dropdown-menu {
        padding: 0px 25px;
        background: none;
    }

    .home-navbar {
        background-color: white;
    }

    /* About us */
    .bar_about_top {
        display: none !important;
    }

    .bar_about_bottom {
        display: none !important;
    }

    .contact_banner_head {
        font-size: 60px;
        font-family: "Satoshi-Regular" !important;
    }

    #circle svg {
        display: none !important;
    }

    .circle-center {
        display: none !important;
    }

    .purposeof_se_parent_section {
        padding-top: 50px;
    }

    .purpose_left h1 {
        margin-bottom: 0px !important;
    }

    .purpose_img_top_content {
        font-size: 22px;
    }

    .purpose_img_bottom_content {
        font-size: 19px;
        left: 11%;
    }

    .purpose_right {
        text-align: left !important;
    }

    .vertical_marque .slider p {
        line-height: 35px;
    }

    .vertical_corosel_parent_section {
        padding: 60px 0px 70px 0px;
    }

    .vertical_marque .slider2 p {
        line-height: 35px;
    }

    .we_connect_para {
        font-size: 20px;
    }

    .section_padding_bottom {
        padding-bottom: 50px;
    }

    .value_margintop {
        margin-top: 35px;
    }

    .heading_para {
        font-family: "Satoshi-Regular" !important;
        color: #000000;
        font-size: 45px;
    }

    .carousel-column img {
        width: 220px;
    }

    .let_connect_left {
        text-align: center;
    }

    .footer_link_columns {
        display: flex;
        justify-content: start !important;
    }

    /* agency  */
    .section_padding {
        padding: 40px 0px;
    }

    .why_digital_market_image {
        margin-top: 20px !important;
    }

    .agency_into_button {
        margin-bottom: 20px !important;
    }

    .agency_detail_whyChoose_left button {
        margin: 25px 0px 20px 0px !important;
    }

    .Agency_added_section_heading h1 {
        margin-bottom: 0px !important;
    }

    .number_list_backred {
        width: 40px;
        height: 40px;
        font-size: 21px;
        margin-top: 0px;
    }

    .Agency_added_content h4 {
        font-size: 35px;
    }

    .why_digital_market_left img {
        margin-bottom: 40px;
    }

    .technology_tabs {
        margin: 50px 0px;
    }

    .branding_design_listing_left {
        padding: 20px 30px 20px !important;
        height: auto;
    }

    .why_digital_market_image_responsive {
        margin-bottom: 0px !important;
    }

    .technology_parent_right {
        margin: 40px 30px;
    }

    .heading_serialnumber {
        font-size: 22px;
        height: 45px;
        margin-top: 7px;
    }

    .first_technology_tab {
        margin-top: 305px;
    }

    .Advisory_marketing_rght_img {
        margin-top: 40px;
    }

    .Advisory_marketing_left_img {
        margin-bottom: 40px;
    }

    .business_goals_content_sec1 {
        padding: 30px 0px 30px 0px !important;
    }

    .business_goals_content_sec2 {
        padding: 0px 0px 30px 0px !important;
    }

    .business_goals_content_sec {
        margin: 30px 0px;
    }

    .eagle_section_right {
        margin-top: 30px;
    }

    .address_div2 {
        margin-top: 40px;
    }
    .agency_detail_listing_left{
        padding: 40px;
    }
    .agency_detail_listing_right{
        padding: 40px !important;
    }

}

.max_width_767_startsmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm {}

@media screen and (max-width: 767px) {
    .bar_about_top {
        display: none !important;
    }

    .bar_about_bottom {
        display: none !important;
    }

    /* aboutus */
    .contact_banner_head {
        font-size: 50px;
        font-family: "Satoshi-Regular" !important;
        text-align: center;
    }

    .contact_banner_img {
        width: 230px;
    }

    .contact_banner_img2 {
        width: 165px;
    }

    #circle svg {
        display: none;
    }

    .circle-center {
        display: none;
    }

    .purposeof_se_parent_section {
        padding-top: 30px;
    }

    .purpose_left h1 {
        font-size: 35px;
    }

    .purpose_img_top_content {
        font-size: 9px;
        position: absolute;
        top: 33%;
        left: 28%;
    }

    .purpose_img_bottom_content {
        font-size: 9px;
    }

    .eagle_section_right {
        padding-left: 1rem !important;
        margin-top: 20px;
    }

    .section_padding {
        padding: 40px 0px;
    }

    .purpose_right {
        text-align: left !important;
    }

    .vertical_marque .slider p {
        line-height: 35px;
    }

    .vertical_marque .slider2 p {
        line-height: 35px;
    }

    .vertical_corosel_parent_section {
        padding: 50px 0px 50px 0px;
    }

    .we_connect_para {
        font-size: 9px;
        left: 17%;
    }

    .section_padding_bottom {
        padding: 0px 0px 30px;
    }

    /* .headings_text_white {
        color: rgb(255, 255, 255);
        font-family: "Satoshi-Regular" !important;
        margin-bottom: 20px;
        font-size: 35px;
    } */

    .heading_para {
        font-family: "Satoshi-Regular" !important;
        color: #000000;
        margin-bottom: 20px;
        font-size: 35px;
    }

    .value_margintop {
        margin-top: 30px;
    }

    .company_vibes_left p {
        padding-bottom: 10PX;
    }

    .carousel-column img {
        width: 104px;
    }

    .let_connect_head {
        font-size: 35px;
        text-align: center;
    }

    .let_connect_right button {
        margin-bottom: 50px;
    }

    .logo_section {
        margin-bottom: 5px;
    }

    .address_div2 {
        margin-top: 30px;
    }

    .footer_links_section {
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    .copy_right p {
        margin-right: 0px;
        text-align: center;
        margin-bottom: 15px !important;
    }

    .vertical_marque .slider p {
        font-size: 25px;
        padding-bottom: 12px;
    }

    .vertical_marque .slider2 p {
        font-size: 25px;
        padding-bottom: 12px;
    }

    .our_philosophy_ubuntu p {
        font-size: 26px
    }

    /* agency pages */
    .section_padding_top {
        padding: 40px 0px 0px;
    }

    .why_digital_market_image {
        width: 100%;
    }

    .agency_detail_whyChoose_left {
        padding: 5px;
        height: 100%;
        padding-top: 30px;
    }

    .agency_detail_whyChoose_rght {
        padding: 25px 5px 25px 25px;
    }

    /* 
    .headings_text_white {
        color: rgb(255, 255, 255);
        font-family: "Satoshi-Regular" !important;
        margin-bottom: 20px;
        font-size: 27px;
    } */
    .purpose_left h1 {
        margin-bottom: 0px;
    }

    .agency_into_button {
        margin-bottom: 20px !important;
    }

    .agency_detail_whyChoose_left button {
        margin: 20px 0px 20px 0px !important;

    }

    .Agency_added_content {
        padding-bottom: 40px;
        margin-top: 30px;
        margin-right: 0px;
    }

    .number_list_backred {
        width: 30px;
        height: 30px;
        font-size: 17px;
        margin-top: 0px;
    }

    .Agency_added_content h4 {
        font-size: 23px;
    }

    .Agency_added_section_heading h1 {
        margin-bottom: 0px !important;
        font-size: 25px;
    }

    .agency_details_button {
        font-size: 13px;
    }

    .why_digital_market_left img {
        margin-bottom: 30px;
    }

    .agency_detail_listing_left {
        padding: 25px !important;
    }

    .branding_design_listing_left {
        padding: 25px 25px 25px !important;
        height: auto;
    }

    .agency_detail_listing_right {
        padding: 25px !important;
    }

    .why_digital_market_right {
        padding-top: 0px !important;
    }

    /* .first_technology_tab {
        margin-top: 80px;
    }
    .technology_tabs {
        margin: 20px 0px;
    } */
    .first_technology_tab {
        margin-top: 285px;
    }

    .technology_parent_right {
        margin: 30px 10px;
    }

    .key_offerings_right h1 {
        font-size: 45px;
    }

    .key_offerings_right button {
        margin-top: 10px !important;
    }

    .keyofferings_content {
        margin: 10px 0px 0px;
    }

    .heading_serialnumber {
        font-size: 22px;
        height: 45px;
    }

    .Advisory_bottom_image_left {
        width: 310px;
        margin-top: 30px;
        position: static;
    }

    .Advisory_bottom_image_right {
        width: 310px;
        float: right;
        margin-top: 30px;
        position: static;
    }

    .contact_bar_whiteback {
        display: none;
    }

    .contact_bar_bottom_whiteback {
        display: none;
    }

    .advisory_content_left h2 {
        font-family: "Satoshi-Regular" !important;
        color: white;
        text-align: center !important;
    }

    .technology_tabs {
        display: none !important;
    }

    #div1 p {
        font-size: 33px;
        line-height: 85.05px;
        letter-spacing: -0.022em;
        padding: 0px 20px;
        -webkit-text-fill-color: transparent;
        -webkit-text-stroke: 1px #FFFFFF30;
    }

    .why_choose_us_content {
        padding: 120px 0px 60px;
    }

    .business_goals_content_sec1 {
        padding: 30px 0px 30px 0px !important;
    }

    .business_goals_content_sec2 {
        padding: 0px 0px 30px 0px !important;
    }

    .footer_boxes h5 {
        margin-top: 30px;
    }

    /* .advisory_details_content_left {
        margin-bottom: 30px;
    } */
    .Advisory_marketing_rght_img {
        margin-top: 30px;
    }

    .Advisory_marketing_left_img {
        margin-bottom: 30px;
    }

    .business_goals_content_sec {
        margin: 20px 0px;
    }

    .break_tag {
        display: block !important;
    }
}